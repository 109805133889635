import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <nav className="flex justify-between items-center mb-6 p-8 container mx-auto gap-12">
      <Link className="block w-[200px] relative" to="/">
        <img width="50" src="/logo.png" alt="logo" />
      </Link>
      <div className="flex gap-8">
        <Link
          className="sm:text-xl text-biege hover:text-yellow underline"
          to="/contact"
        >
          Contact
        </Link>
      </div>
    </nav>
  );
}
