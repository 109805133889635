import React from "react";

export default function Footer() {
  return (
    <footer className="mx-2 sm:mx-8 py-8 lg:pt-20 relative overflow-hidden text-primary text-center font-normal">
      <div className="container mx-auto px-8 mb-4 flex justify-center">
        <img src="/logo.png" alt="Bytes" width="48" />
      </div>
      <p className="mb-2">Made with ❤️ in India By <a
          href="https://www.011bq.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          011BQ
        </a>
</p>
      <p className="text-xs">
        All rights reserved © {new Date().getFullYear()} 
      </p>

    </footer>
  );
}
