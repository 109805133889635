import mixpanel from "mixpanel-browser";

// Mix panel key here
mixpanel.init("9e873e8f2e75c51c797b27d3689fd0bd");
let env_check = process.env.NODE_ENV === "production";

let actions = {
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
};

export let Mixpanel = actions;
