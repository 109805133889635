import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Layout from "./components/layout";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "./components/Mixpanel";


export default function Home() {
  const navigate = useNavigate();
  // get the search params for the current location
  const searchParams = new URLSearchParams(window.location.search);
  const searchQuery = searchParams.get("video");

  Mixpanel.track('Home');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [thumb, setThumb] = useState("");

  useEffect(() => {
    if (searchQuery !== null) {
      setThumb(searchQuery);

      const urlValues = searchQuery.replace(/http/g, " $&").split(" ");
      const final = urlValues[2].split("?");

      setValue("video_url", final[0].split("&instant=")[0]);
      setValue("time", final[0].split("&instant=")[1]);
    }
    // Mixpanel.track("Home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const onSubmit = async (data) => {
    const { video_url, time, width } = data;

    var temp = !width
      ? `https://api.videotothumbnail.com/v1/thumbnail.jpeg?video_url=${video_url}&instant=${
          time ? time : 1
        }`
      : `https://api.videotothumbnail.com/v1/thumbnail.jpeg?video_url=${video_url}&instant=${
          time ? time : 1
        }&width=${width}`;

    setThumb(temp);

    searchParams.set("video", temp);
    navigate({
      search: searchParams.toString(),
    });
  };

  return (
    <Layout>
      <div className="container mx-auto relative px-8">
        <div className="flex justify-between mb-36">
          <div className="max-w-4xl mx-auto">
            <div className="text-white inline-block text-center pt-14">
              <img
                className="mx-auto w-[200px] md:w-[300px]"
                src="/vtt.png"
                width="300"
                alt="Bytes, Your weekly dose of JavaScript"
              />
              <h3 className="text-lg sm:text-2xl my-4 mb-12 max-w-[50ch] mx-auto text-primary">
                Extract thumbnails from MP4 videos. <br /> Just enter the Video
                URL and the time instant at which you want the thumbnail.
              </h3>
              <form
                className="mx-auto mb-6 font-normal text-primary"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="md:flex md:flex-row md:gap-2 flex flex-col gap-6">
                  <div className="md:flex items-center w-full">
                    <label className="text-sm mr-2">URL</label>
                    <input
                      className={`bg-charcoal w-full rounded appearance-none float-left text-sm mt-0 p-4 h-10 focus:outline-none ${
                        errors.name ? "border border-red-500" : ""
                      }`}
                      type="url"
                      name="video_url"
                      placeholder="Enter Video URL"
                      autoComplete="off"
                      {...register("video_url", { required: true })}
                    />
                  </div>
                  <div className="md:flex items-center">
                    <label className="text-sm mr-2">Playback Instant</label>
                    <input
                      className="bg-charcoal md:w-3/6 w-full rounded appearance-none float-left text-sm mt-0 p-4 h-10 border-none focus:outline-none"
                      type="text"
                      placeholder=""
                      {...register("time")}
                    />
                  </div>
                  <div className="md:flex items-center">
                    <label className="text-sm mr-2">Width</label>
                    <input
                      className="bg-charcoal md:w-3/6 w-full rounded appearance-none float-left text-sm mt-0 p-4 h-10 border-none focus:outline-none"
                      type="text"
                      placeholder=""
                      disabled
                      {...register("width")}
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-orange py-2 px-3 text-black text-sm rounded bg-yellow float-left shadow-none uppercase focus:outline-none"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {thumb ? <img src={thumb} className="mx-auto" alt="thumb" /> : <></>}
      </div>
    </Layout>
  );
}