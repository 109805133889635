import React from "react";
import Home from "./Home";
import Contact from "./contact";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Mixpanel } from "./components/Mixpanel";

function App() {
  Mixpanel.track('VideoThumbnail');
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
