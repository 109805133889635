import React from "react";
import Layout from "./components/layout";
import { useForm } from "react-hook-form";
import axios from "axios";
import swal from "sweetalert";
import { Mixpanel } from "./components/Mixpanel";

export default function Contact() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  Mixpanel.track('ContactUs');

  const onSubmit = async (data) => {
    const { name, email, message } = data;

    const webhookUrl =
      "https://hooks.slack.com/services/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";

    const postData = {
      text: `Site: VideoToThumbnail\nName: ${name}\nEmail: ${email}\nMessage: ${message}`,
    };

    let res = await axios.post(webhookUrl, JSON.stringify(postData), {
      withCredentials: false,
      transformRequest: [
        (postData) => {
          return postData;
        },
      ],
    });

    if (res.status === 200) {
      swal({
        title: "Thank you!",
        text: "Will get back to you soon !!",
        icon: "success",
        button: "Close",
      });
      reset();
    } else {
      console.log("There was an error.  Please try again later.");
    }
  };

  return (
    <Layout>
      <div className="text-primary">
        <h2 className="text-center text-2xl sm:text-4xl font-black md:text-5xl mb-8 text-biege">
          Get in touch
        </h2>
        <div className="relative max-w-2xl mx-auto">
          <div className="py-4 sm:py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <form
                className="grid grid-cols-1 gap-y-6 font-normal"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  <label htmlFor="name" className="sr-only">
                    Name
                  </label>
                  <input
                    className={`bg-charcoal text-white block w-full shadow-sm py-3 px-4 rounded-md focus:outline-none ${
                      errors.name ? "border border-red-500" : ""
                    }`}
                    id="name"
                    type="text"
                    placeholder="Name"
                    autoComplete="off"
                    name="name"
                    {...register("name", { required: true })}
                  />
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    className={`bg-charcoal text-white block w-full shadow-sm py-3 px-4 rounded-md focus:outline-none ${
                      errors.email ? "border border-red-500" : ""
                    }`}
                    id="email"
                    type="email"
                    placeholder="Email"
                    autoComplete="off"
                    name="email"
                    {...register("email", {
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    })}
                  />
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    How can we help?
                  </label>
                  <textarea
                    className={`bg-charcoal text-white block w-full shadow-sm py-3 px-4 rounded-md focus:outline-none ${
                      errors.message ? "border border-red-500" : ""
                    }`}
                    id="message"
                    rows="6"
                    type="text"
                    placeholder="How can we help?"
                    autoComplete="off"
                    name="message"
                    {...register("message", { required: true })}
                  />
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-3 px-6 border shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-biege"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
