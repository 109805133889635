import React, { useEffect } from "react";
import Header from "./header";
import Footer from "./footer";

export default function Layout({ children }) {
  useEffect(() => {
    // Include the Crisp code here, without the <script></script> tags
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "992701c4-c95d-4e27-9be5-d295eec59291";

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);

  return (
    <div className="bg-[#0f0d0e] text-[#F9F4DA] font-mac font-semibold">
      <Header />
      {children}
      <Footer />
    </div>
  );
}
